import http from '../util/http.js'

export const List = (params) => http.get('/log/list',params)









